










































import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'Header',
  props: {
    title: {
      type: String,
      required: true
    },
    step: {
      type: Number,
      required: true
    }
  }
})
